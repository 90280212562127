import { useEffect, useRef, useState } from 'react';
import {
  AudioConfig,
  AutoDetectSourceLanguageConfig,
  PropertyId,
  ResultReason,
  SpeechConfig,
  SpeechRecognizer
} from 'microsoft-cognitiveservices-speech-sdk';

import { logger } from 'shared';

const cogSvcRegion = 'westeurope';
const cogSvcSubKey = '3275a05104da4a6e8815e98890de3586';

export const useSpeechRecognition = (
  sttLocale: string,
  onMessage = (msg: string) => {},
  stopSpeaking = () => {}
) => {
  const [isRecordingMic, setIsRecordingMic] = useState(false);
  const speechRecognizerRef = useRef<SpeechRecognizer | null>(null);

  useEffect(() => {
    const speechRecognitionConfig = SpeechConfig.fromEndpoint(
      new URL(
        `wss://${cogSvcRegion}.stt.speech.microsoft.com/speech/universal/v2`
      ),
      cogSvcSubKey
    );
    speechRecognitionConfig.setProperty(
      PropertyId.SpeechServiceConnection_LanguageIdMode,
      'Continuous'
    );
    speechRecognitionConfig.setProperty(
      PropertyId.Speech_SegmentationSilenceTimeoutMs,
      '150'
    );
    const autoDetectSourceLanguageConfig =
      AutoDetectSourceLanguageConfig.fromLanguages([sttLocale]);

    if (isRecordingMic) {
      speechRecognizerRef.current?.stopContinuousRecognitionAsync();
      stopMicrophone();
    }
    speechRecognizerRef.current = SpeechRecognizer.FromConfig(
      speechRecognitionConfig,
      autoDetectSourceLanguageConfig,
      AudioConfig.fromDefaultMicrophoneInput()
    );

    if (isRecordingMic) {
      startMicrophone();
    }
  }, [sttLocale]);
  // }, []);

  const stopMicrophone = () => {
    speechRecognizerRef.current?.stopContinuousRecognitionAsync(
      () => {
        setIsRecordingMic(false);
      },
      (err) => {
        logger.debug(err, 'Failed to stop continuous recognition:');
        setIsRecordingMic(false);
      }
    );
  };

  const startMicrophone = () => {
    logger.debug('startMicrophone');
    if (!speechRecognizerRef.current)
      return logger.debug('speechRecognizerRef.current is null');

    speechRecognizerRef.current.recognized = async (s, e) => {
      if (e.result.reason === ResultReason.RecognizedSpeech) {
        const userQuery = e.result.text.trim();
        if (userQuery === '' || userQuery === 'Play.') {
          return;
        }
        if (/stop stop/i.test(userQuery.replace('.', ''))) {
          return stopSpeaking();
        }

        onMessage(userQuery);
      }
    };

    if (isRecordingMic) return;

    speechRecognizerRef.current.startContinuousRecognitionAsync(
      () => {
        setIsRecordingMic(true);
      },
      (err) => {
        logger.debug(err, 'Failed to start continuous recognition:');
        setIsRecordingMic(false);
      }
    );
  };

  return {
    isRecordingMic,
    startMicrophone,
    stopMicrophone
  };
};
