import { videoCallStore } from './videoCallStore';
import { useTracks, UseTracksOptions } from '@livekit/components-react';
import { Track } from 'livekit-client';
import { useMemo } from 'react';

export const useBotTrack = (options?: UseTracksOptions) => {
  const BotIdentity = videoCallStore((state) => state.secrets?.botIdentity);

  const tracks = useTracks(
    [{ source: Track.Source.Camera, withPlaceholder: true }],
    options
  );

  return useMemo(
    // () => tracks.find((t) => t?.participant?.identity === 'dd'), // ? For debug
    () => tracks.find((t) => t?.participant?.identity === BotIdentity),
    [tracks]
  );
};
