import { ENVIRONMENT } from '../model/config/config';

/**
 * Logger utility for debugging purposes.
 *
 * The `debug` method logs messages to the console if the application is running
 * in either 'development' or 'local' environments. This can help developers
 * trace and debug the flow of their application during the development phase.
 *
 * @namespace logger
 * @property {Function} debug - Logs the provided arguments to the console if the environment is 'development' or 'local'.
 * @param {...any} args - The messages or objects to be logged to the console.
 *
 * @example
 * logger.debug('This is a debug message', { some: 'object' });
 * // Output: This is a debug message { some: 'object' }
 */
export const logger = {
  debug: (...args: any[]) => {
    if (ENVIRONMENT === 'development' || ENVIRONMENT === 'local') {
      console.log(...args);
    }
  }
};
