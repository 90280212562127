import * as Yup from 'yup';
import { useEffect, useState } from 'react';
import { Link, useLocation, useNavigate, useParams } from 'react-router-dom';
import {
  FilledButton,
  IAvatar,
  MAIN_ROUTE,
  OutlinedButton,
  showError,
  Loader,
  PERSONA_DEFINITION_CHAR_LIMIT,
  cn,
  logger,
  useQueryParams
} from 'shared';

import { TopBar } from './TopBar';
import {
  ChooseAvatar,
  ChooseEngine,
  Knowledge,
  steps
} from 'widgets/create-persona';

import { avatarStore } from 'entities/avatar';
import {
  ICreatePersonaReq,
  IKnowledgeReq,
  initialPersona,
  IPersonaFormik,
  personaStore
} from 'entities/persona';
import { useFormik } from 'formik';

import { useAuthStore } from 'stores/useAuthStore';
import { ChooseVoice } from 'features/choose-voice';
import { PersonaDataForm } from 'features/persona-form';
import { creditStore } from 'entities/credit';
import { userStore } from 'entities/user';

/**
 * CreatePersonaPage component.
 *
 * This component allows users to create a new persona by going through multiple steps.
 * It handles form submission, state management, and conditional rendering based on the current step.
 *
 */

export const CreatePersonaPage = () => {
  const { isChatAvailable } = creditStore();
  const { user } = userStore();

  const [currentStep, setCurrentStep] = useState(1);

  const { getAvatarById, avatarsLoading } = avatarStore();

  const [avatar, setAvatar] = useState<IAvatar>();

  const { isPersonaCreating, addPersona } = personaStore();

  const nav = useNavigate();

  const [files, setFiles] = useState<string[]>([]);

  const handleChangeStep = (step: number) => {
    setCurrentStep(step);
  };

  const handleChangeFiles = (files: string[]) => {
    setFiles(files);
  };

  const onSubmit = async (values: IPersonaFormik) => {
    const submitValues = { ...values };
    if (
      submitValues.knowledgeSources.length > 0 &&
      submitValues.knowledgeSources[0].type === 'Ungrounded'
    ) {
      submitValues.knowledgeSources = [];
    }
    if (submitValues.name === '') {
      submitValues.name = 'My Video Agent';
    }
    const persona = await addPersona(submitValues);
    if (persona) nav(MAIN_ROUTE + '?posthog-agents-create=true');
  };

  const formik = useFormik<IPersonaFormik>({
    initialValues: initialPersona,
    validationSchema: Yup.object({
      name: Yup.string(),
      description: Yup.string()
        .required('Please enter persona description')
        .max(100, 'Please make description shorter'),
      definition: Yup.string().max(
        PERSONA_DEFINITION_CHAR_LIMIT,
        'Please make description shorter'
      ),
      greeting: Yup.string().max(500, 'Please make greeting shorter')
    }),
    onSubmit
  });

  // useEffect(() => {
  //   logger.debug(formik.values, 'formik values');
  // }, [formik.values]);

  const avatarId = useQueryParams().get('avatar');

  useEffect(() => {
    if (avatarId) {
      formik.setFieldValue('avatarId', avatarId);
    }
  }, [avatarId]);

  useEffect(() => {
    if (!avatarsLoading) setAvatar(getAvatarById(formik.values.avatarId));
  }, [formik.values.avatarId, avatarsLoading]);

  useEffect(() => {
    if (!user) {
      showError('You need to login to access this page.');
      nav(MAIN_ROUTE);
    }
  }, [user]);

  const isStepWithAvatarPreview = currentStep === 1 || currentStep === 2;

  return (
    <div className="ag-bg-neutral-900 ag-text-neutral-100 ag-h-screen ag-overflow-auto ag-relative sm:ag-static ag-pt-0 ">
      <TopBar currentStep={currentStep} onStepChange={handleChangeStep} />
      <form
        onSubmit={formik.handleSubmit}
        className="ag-relative  sm:ag-static ag-pb-[63px] sm:ag-pb-0 ag-grid ag-grid-rows-[1fr,auto]"
      >
        <div className="ag-h-full ag-grid ag-grid-rows-[auto,1fr]">
          {isStepWithAvatarPreview ? (
            <div className="ag-size-[12rem] sm:ag-size-56 ag-aspect-square ag-mx-auto ag-overflow-hidden ag-rounded-xl ag-border-none ag-mt-9 sm:ag-mt-10 ag-mb-8">
              {!!avatar && (
                <img
                  src={
                    avatar?.inputImageUrl ??
                    avatar?.menuImageUrl ??
                    avatar?.rawImageUrl
                  }
                  className="ag-w-full ag-h-full ag-object-cover ag-border-none"
                  alt="avatar"
                />
              )}
            </div>
          ) : (
            <div />
          )}
          <div
            // className={cn(
            //   'ag-mx-auto ag-h-full ag-overflow-auto ag-w-full md:ag-px-[calc((100vw-36rem)/2)] ag-px-4 sm:ag-px-6 md:ag-min-h-[350px]',
            //   !isStepWithAvatarPreview && 'ag-pt-10 ag-pb-20'
            // )}

            className={cn(
              'ag-mx-auto ag-w-full md:ag-px-[calc((100vw-36rem)/2)] ag-px-4 sm:ag-px-6',
              !isStepWithAvatarPreview && 'ag-pt-10 ag-pb-20'
            )}
          >
            {currentStep === 1 && (
              <ChooseAvatar
                avatarId={formik.values.avatarId}
                onChange={(value: string) =>
                  formik.setFieldValue('avatarId', value)
                }
              />
            )}
            {currentStep === 2 && (
              <div className="ag-w-full ag-grid ag-grid-rows-[auto,1fr] ag-h-full ag-overflow-auto">
                <div className="ag-w-full ag-flex ag-items-center ag-justify-between ag-mb-6 sm:ag-mb-10">
                  <div>Select a voice for your video agent</div>
                </div>

                <div className="ag-grid ag-grid-rows-[auto,1fr] ag-h-full ag-overflow-auto">
                  <ChooseVoice
                    voice={formik.values.voice}
                    avatarGender={avatar?.gender}
                    onChange={(value: string) =>
                      formik.setFieldValue('voice', value)
                    }
                  />
                </div>
              </div>
            )}
            {currentStep === 3 && <PersonaDataForm formik={formik} />}
            {currentStep === 4 && (
              <Knowledge
                files={files}
                onChangeFile={handleChangeFiles}
                formik={formik}
                onChange={(value: IKnowledgeReq[]) =>
                  formik.setFieldValue('knowledgeSources', value)
                }
                isCreate
              />
            )}
            {currentStep === 5 && (
              <ChooseEngine
                formik={formik}
                onChange={(value: string) =>
                  formik.setFieldValue('model', value)
                }
              />
            )}
          </div>
        </div>

        <div className="ag-fixed sm:ag-absolute static ag-bottom-0 ag-left-0 ag-flex ag-items-center ag-w-full ag-border-t ag-px-4 sm:ag-px-6 ag-py-3 ag-justify-end ag-gap-4 ag-bg-neutral-800 ag-border-neutral-700  ">
          {currentStep === 1 && (
            <Link to={MAIN_ROUTE} className="ag-block ag-w-1/2 sm:ag-w-auto">
              <OutlinedButton className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-block ag-w-full sm:ag-w-36">
                Cancel
              </OutlinedButton>
            </Link>
          )}
          {currentStep !== 1 && (
            <OutlinedButton
              onClick={() => handleChangeStep(currentStep - 1)}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
            >
              Back
            </OutlinedButton>
          )}
          {currentStep !== steps.length && (
            <FilledButton
              type="button"
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36"
              onClick={async () => {
                if (currentStep === 3) {
                  const errors = await formik.validateForm();

                  if (Object.keys(errors).length > 0) {
                    for (const err in errors) {
                      showError((errors as { [key: string]: string })[err]);
                    }
                    return;
                  }
                }
                handleChangeStep(currentStep + 1);
              }}
            >
              Next
            </FilledButton>
          )}
          {currentStep === steps.length && (
            <FilledButton
              type="submit"
              disabled={isPersonaCreating}
              onClick={() => {
                if (Object.keys(formik.errors).length > 0) {
                  Object.keys(formik.errors).forEach((e) =>
                    showError((formik.errors as { [key: string]: string })[e])
                  );
                  return;
                }
              }}
              className="ag-text-sm ag-text-white ag-px-4 ag-py-2 ag-w-1/2 sm:ag-w-36 disabled:ag-bg-primary-600"
            >
              {isPersonaCreating ? <Loader isButton size={20} /> : 'Create'}
            </FilledButton>
          )}
        </div>
      </form>
    </div>
  );
};
