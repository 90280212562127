import { useNavigate, useParams } from 'react-router';
import { useEffect } from 'react';
import {
  MAIN_ROUTE,
  Loader,
  LOGIN_ROUTE,
  useQueryParams,
  showError,
  logger
} from 'shared';
import { videoCallStore } from 'features/livekit-call/';
import FullscreenView from './FullscreenView';
import { useAuthStore } from 'stores/useAuthStore';
import { Layout } from 'widgets/layout';
import { creditStore } from 'entities/credit';
import { userStore } from 'entities/user';

export const Call = () => {
  const { getLivekitToken, isCallLoading, secrets, persona, endCall } =
    videoCallStore();
  const { user } = userStore();
  const { isChatAvailable } = creditStore();

  const { id } = useParams();
  const query = useQueryParams();

  const nav = useNavigate();

  useEffect(() => {
    if (!id) return nav(MAIN_ROUTE);

    if (query.get('apiKey') || query.get('agentKey')) {
      // logger.debug('apiKey or agentKey found in query params');

      const agentKey = query.get('agentKey');
      const apiKey = query.get('apiKey');

      getLivekitToken(id as string, { apiKey, agentKey }).then((success) => {
        if (!success) {
          nav(MAIN_ROUTE);
        }
      });
      return;
    }

    if (user && isChatAvailable) {
      getLivekitToken(id).then((success) => !success && nav(MAIN_ROUTE));
    }
  }, [user, isChatAvailable]);

  return (
    <>
      {isCallLoading || !secrets || !persona ? (
        <div className="ag-w-full ag-h-screen ag-bg-neutral-900 ag-flex ag-items-center ag-justify-center   ">
          <Loader size={96} />
        </div>
      ) : (
        <FullscreenView />
      )}
    </>
  );
};
