import { useDataChannel } from '@livekit/components-react';
import { useMorph } from 'features/recognize-emotions';
import { logger, useExternalScript } from 'shared/lib';
import { DatachannelMessageType, videoCallStore } from '.';
import { useSpeechRecognition } from 'features/speech-recognition';
import { useEffect, useState } from 'react';
import { MAIN_ROUTE } from 'shared/model';
import { useLocation, useNavigate } from 'react-router';

export const useCall = () => {
  const location = useLocation();

  const mphToolsState = useExternalScript(
    'https://sdk.morphcast.com/mphtools/v1.0/mphtools.js'
  );
  const aiSdkState = useExternalScript(
    'https://ai-sdk.morphcast.com/v1.16/ai-sdk.js'
  );

  const { start, stop, isWebcam, loading: webcamLoading, load } = useMorph();

  useEffect(() => {
    load(aiSdkState, mphToolsState);
  }, [aiSdkState, mphToolsState]);

  const [message, setMessage] = useState('');

  const dc = useDataChannel();

  const {
    isConnected,
    dataChannelMessage,
    sendDataChannel,
    messages,
    addMessage,
    secrets,
    sttLocale,
    persona,
    endCall
  } = videoCallStore();

  const sendJsonMessage = (
    data: { type: DatachannelMessageType } & { [k: string]: any }
  ) => {
    logger.debug(data, 'json message');
    dc.send(new TextEncoder().encode(JSON.stringify(data)), {});
  };

  useEffect(() => {
    if (!dataChannelMessage || !isConnected) return;
    sendDataChannel(null);
    sendJsonMessage(dataChannelMessage);
  }, [isConnected, dataChannelMessage]);

  useEffect(() => {
    if (!persona || !isConnected) return;
    sendJsonMessage({
      type: DatachannelMessageType.Avatar
    });
  }, [isConnected, persona]);

  useEffect(() => {
    if (messages.length === 0 && isConnected) {
      sendJsonMessage({
        type: DatachannelMessageType.ClearChat
      });
    }
  }, [messages]);

  const { isRecordingMic, stopMicrophone, startMicrophone } =
    useSpeechRecognition(sttLocale, (msg) => {
      sendJsonMessage({
        message: msg,
        type: DatachannelMessageType.MicrophoneTranscription,
        affect: useMorph.getState().affects
      });
      addMessage({
        role: 'user',
        content: msg,
        timestamp: Date.now(),
        affect: useMorph.getState().getDominantAffect()
      });
    });

  const nav = useNavigate();

  useEffect(() => {
    return () => {
      handleEndCall();
    };
  }, []);

  useEffect(() => {
    if (isConnected && persona) {
      sendJsonMessage({
        type: DatachannelMessageType.Avatar,
        avatar: persona.avatar.id
      });

      sendJsonMessage({
        type: DatachannelMessageType.SystemMessage,
        context: persona.description
      });

      sendJsonMessage({
        type: DatachannelMessageType.Voice,
        voice: persona.voice
      });
    }
  }, [persona, isConnected]);

  useDataChannel((msg) => {
    if (msg.from?.identity !== secrets?.botIdentity) return;
    const data: unknown = JSON.parse(new TextDecoder().decode(msg.payload));
    if (
      typeof data === 'object' &&
      data &&
      'message' in data &&
      typeof data.message === 'string' &&
      'timestamp' in data &&
      typeof data.timestamp === 'number'
    ) {
      addMessage({
        role: 'assistant',
        timestamp: data.timestamp,
        content: data.message
      });
    } else {
      logger.debug(data, 'received from dc:');
    }
  });

  const handleSendMessage = (msg = message) => {
    sendJsonMessage({
      message: msg,
      type: DatachannelMessageType.TextboxInput,
      affect: useMorph.getState().affects
    });
    addMessage({
      role: 'user',
      content: msg,
      timestamp: Date.now(),
      affect: useMorph.getState().getDominantAffect()
    });
    setMessage('');
  };

  const handleEndCall = () => {
    endCall();

    stopMicrophone();
    stop();
  };

  return {
    handleEndCall,
    handleSendMessage,
    startWebcam: start,
    stopWebcam: stop,
    webcamLoading,
    isRecordingMic,
    startMicrophone,
    stopMicrophone,
    message,
    isWebcam,
    setMessage,
    sendJsonMessage
  };
};
